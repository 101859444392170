import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { TextField, Grid } from '@material-ui/core';
import axios from '../../services/axios';

import { Form, Bloco, CheckboxWrapper } from './styled';
import { Container } from '../../styles/GlobalStyles';
import Loading from '../../components/Loading';

export default function Edit() {
  const { id } = useParams();
  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [nome_fantasia, setNomeFantasia] = useState('');
  const [situacao, setSituacao] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cep, setCep] = useState('');
  const [endereco, setEndereco] = useState('');
  const [tokenEnterprice, setTokenEnterprice] = useState('');
  const [tokenCollaborator, setTokenCollaborator] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [total_quotas, setTotalQuotas] = useState('');
  const [used_quotas, setUsedQuotas] = useState('');
  const [estado, setEstado] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      try {
        const { data } = await axios.get(`/empresa/${id}`);
        console.log(data);

        setNome(data.nome || '');
        setCnpj(data.cnpj || '');
        setNomeFantasia(data.fantasia || '');
        setTelefone(data.telefone || '');
        setCep(data.cep || '');
        setEndereco(data.logradouro || '');
        setTokenCollaborator(data.token_collaborator || '');
        setTokenEnterprice(data.token_enterprise || '');
        setEmail(data.email || '');
        setTotalQuotas(String(data.total_quotas) || '');
        setUsedQuotas(String(data.used_quotas) || '');
        setNumero(data.numero || '');
        setBairro(data.bairro || '');
        setCidade(data.municipio || '');
        setEstado(data.uf || '');
        setDateStart(data.abertura ? new Date(data.abertura).toISOString().split('T')[0] : '');
        setSituacao(data.situacao || '');
        setIsBlocked(data.blocked || false);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);

        if (status === 400) errors.map((error) => toast.error(error));
      }
    }

    getData();
  }, [id]);

  const handleGenerateTokenOne = async () => {
    try {
      const response = await axios.post('/empresa/tokenCollaborator', { cnpj });
      localStorage.setItem('tokenMessage', 'Token gerado com sucesso!');
      setTokenCollaborator(response.data.token);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateQuotas = async () => {
    try {
      const totalQuotasValue = total_quotas === '' ? 0 : Number(total_quotas);
      const usedQuotasValue = used_quotas === '' ? 0 : Number(used_quotas);

      const response = await axios.put(`/empresa/${id}`, {
        total_quotas: totalQuotasValue,
        used_quotas: usedQuotasValue,
      });

      localStorage.setItem('tokenMessage', 'Cotas modificadas com sucesso!');
      window.location.reload();
      setTotalQuotas(String(response.data.totalQuotas));
      setUsedQuotas(String(response.data.usedQuotas));
    } catch (err) {
      console.log(err);
      toast.error('Erro ao atualizar as cotas');
    }
  };

  const handleGenerateTokenTwo = async () => {
    try {
      const response = await axios.post('/empresa/tokenEnterprice', { cnpj });
      localStorage.setItem('tokenMessage', 'Token gerado com sucesso!');
      setTokenEnterprice(response.data.token);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const tokenMessage = localStorage.getItem('tokenMessage');
    if (tokenMessage) {
      toast.success(tokenMessage);
      localStorage.removeItem('tokenMessage');
    }
  }, [id]);

  return (
    <Container>
      <Bloco>
        <Loading isLoading={isLoading} />
        <Form>
          <h1>Editar Empresa</h1>
          <Grid container spacing={2} />
          <Grid item xs={12} md={6}>
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="CNPJ"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
              disabled
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              disabled
            />
            <TextField
              className="text-field"
              label="Nome Fantasia"
              value={nome_fantasia}
              disabled
              onChange={(e) => setNomeFantasia(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Telefone"
              type="number"
              value={telefone}
              disabled
              onChange={(e) => setTelefone(e.target.value)}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Cep"
              value={cep}
              disabled
              onChange={(e) => setCep(e.target.value)}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Endereço"
              value={endereco}
              disabled
              onChange={(e) => setEndereco(e.target.value)}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Número"
              value={numero}
              disabled
              onChange={(e) => setNumero(e.target.value)}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Token Colaborador"
              value={tokenCollaborator}
              disabled
              onChange={(e) => setTokenCollaborator(e.target.value)}
            />
            <button type="button" onClick={handleGenerateTokenOne}>
              Gerar Token Colaborador
            </button>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Bairro"
              value={bairro}
              disabled
              onChange={(e) => setBairro(e.target.value)}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Cidade"
              value={cidade}
              disabled
              onChange={(e) => setCidade(e.target.value)}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Estado"
              value={estado}
              disabled
              onChange={(e) => setEstado(e.target.value)}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Situação da empresa"
              value={situacao}
              disabled
              onChange={(e) => setDateStart(e.target.value)}
            />
            <TextField
              className="text-field"
              label="Email"
              type="email"
              value={email}
              disabled
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              InputProps={{
                disableUnderline: true,
              }}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Token Empresa"
              value={tokenEnterprice}
              disabled
              onChange={(e) => setTokenEnterprice(e.target.value)}
            />
            <button type="button" onClick={handleGenerateTokenTwo}>
              Gerar Token Empresa
            </button>
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Total de quotas"
              value={total_quotas}
              onChange={(e) => setTotalQuotas(e.target.value)}
            />
            <button type="button" onClick={handleUpdateQuotas}>
              Atualizar Cotas
            </button>
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Quotas utilizadas"
              value={used_quotas}
              disabled
              onChange={(e) => setUsedQuotas(e.target.value)}
            />
            <CheckboxWrapper className="text">
              <label htmlFor="Bloquear">
                {' '}
                <input type="checkbox" checked={isBlocked} onChange={() => setIsBlocked(!isBlocked)} />
                <p>Bloquear</p>
              </label>
              <Link to={`/adm/${id}/users`}>
                <button type="button" className="no-hover">
                  Editar Usuários
                </button>
              </Link>
            </CheckboxWrapper>
          </Grid>
        </Form>
      </Bloco>
    </Container>
  );
}
