import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import Background from '../../assets/img/background.png';
import Certificado from '../../assets/img/CertificadoIcon.svg';

import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer, StyledCard } from './styled';
import axios from '../../services/axios';

import Loading from '../../components/Loading';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    color: '#000',
    justifyContent: 'flex-start',
    padding: 50,
  },
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    padding: 12,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginTop: 100,
  },
  footer: {
    fontSize: 20,
    marginTop: 25,
    textAlign: 'center',
    paddingTop: 10,
    fontWeight: 1000,
  },
  text: {
    fontSize: 14,
    marginTop: 80,
  },
  text2: {
    fontSize: 14,
    marginTop: 40,
    marginBottom: 45,
  },
  textdata: {
    marginTop: 35,
    marginBottom: 45,
    textAlign: 'center',
  },
});
function MyDocument({ name, data }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.backgroundContainer}>
          <Image style={styles.backgroundImage} src={Background} />
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>ATESTADO</Text>
          <Text style={styles.text}>
            Certificamos, para os devidos fins de auditoria e compliance, que a empresa {name.toUpperCase()} realiza de
            forma contínua e efetiva atividades voltadas à cultura e conscientização em segurança da informação e
            privacidade de dados. Essas atividades são conduzidas com o objetivo de educar e capacitar seus
            colaboradores, promovendo boas práticas e comportamentos alinhados com as diretrizes e regulamentações
            vigentes.Essas iniciativas visam fortalecer a cultura organizacional em torno da segurança e da privacidade,
            assegurando que todos os colaboradores estejam cientes de suas responsabilidades e capacitados para
            identificar, mitigar e reportar potenciais ameaças.
          </Text>
          <Text style={styles.textdata}>
            Data: <Text style={styles.text}>{data}</Text>
          </Text>
          <Text style={styles.footer}>BlackBelt CyberSensei</Text>
        </View>
      </Page>
    </Document>
  );
}

export default function Certificaiton() {
  const cnpj = useSelector((state) => state.auth.user.cnpj_enterprise);
  const [isLoading, setIsLoading] = React.useState(false);
  const data = new Date().toLocaleDateString('pt-BR');
  const [hasCertification, setHasCertification] = React.useState(false);
  const [name, setName] = React.useState('');
  console.log('nome aqui', name);
  useEffect(() => {
    async function getCourses() {
      setIsLoading(true);
      try {
        const response = await axios.get(`/rhusers`);
        const filteredUsers = response.data.filter((user) => user.cnpj_enterprise === cnpj);
        const userIds = filteredUsers.map((user) => user.id);
        const result = await axios.get('/usercourse');
        const filteredCourses = result.data.filter(
          (course) => userIds.includes(course.userId) && course.completionPercentage === 100,
        );
        if (filteredCourses.length > 0) {
          setHasCertification(true);
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getCourses();
  }, []);

  useEffect(() => {
    async function getUsers() {
      setIsLoading(true);
      try {
        const responseEmpresa = await axios.get(`/empresa`);
        console.log(responseEmpresa);
        const filterEmp = responseEmpresa.data.filter((emp) => emp.cnpj === cnpj);
        const empresas = filterEmp[0];
        console.log(empresas.nome);
        setName(empresas.nome);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getUsers();
  }, []);

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <img className="svg" src={Certificado} alt="Certificado" />
              <CardActions>
                {hasCertification ? (
                  <Button>
                    <PDFDownloadLink document={<MyDocument data={data} name={name} />} fileName="certificado.pdf">
                      {({ loading }) => (loading ? 'Loading document...' : 'Download Certificado!')}
                    </PDFDownloadLink>
                  </Button>
                ) : (
                  <Typography>Você não tem nenhum colaborador graduado ainda</Typography>
                )}
              </CardActions>
            </StyledCard>
          </Grid>
        </Grid>
      </CollaboratorContainer>
    </Container>
  );
}

MyDocument.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
};
