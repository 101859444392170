import React, { useState, useEffect } from 'react';
import {
  FaHome,
  FaPowerOff,
  FaUserEdit,
  FaUserSlash,
  FaAddressCard,
  FaUserCog,
  FaUserPlus,
  FaNewspaper,
  FaCertificate,
  FaQuestionCircle,
} from 'react-icons/fa';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Modal from '@mui/material/Modal';

import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import axios from '../../services/axios';

import useIsAdmin from '../../store/modules/isAdmin';
import useIsEmpresa from '../../store/modules/isEmpresa';
import { ReactComponent as Logo } from '../../assets/img/CyberSensei.svg';
import { ReactComponent as AvatarOff } from '../../assets/img/AvatarOff.svg';
import { ReactComponent as Moldura } from '../../assets/img/FaixaBranca.svg';
import { ReactComponent as Moldura_Azul } from '../../assets/img/FaixaAzul.svg';
import { ReactComponent as Moldura_Roxa } from '../../assets/img/FaixaRoxa.svg';
import { ReactComponent as Moldura_Marrom } from '../../assets/img/FaixaMarrom.svg';
import { ReactComponent as Moldura_Preta } from '../../assets/img/FaixaPreta.svg';

import * as actions from '../../store/modules/auth/actions';
import { Nav, SecondNav, StyledP } from './styled';
import EditUser from '../../pages/EditUser';
import Admin from '../../pages/admin';
import Register from '../../pages/Register';

export default function Header() {
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleOpenEditUser = () => setOpenEditUser(true);
  const handleCloseEditUser = () => setOpenEditUser(false);
  const handleOpenAdmin = () => setOpenAdmin(true);
  const handleCloseAdmin = () => setOpenAdmin(false);
  const handleCloseRegister = () => setOpenRegister(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const id = useSelector((state) => state.auth.user.id);
  const history = useHistory();
  const userNome = useSelector((state) => state.auth.user.nome);
  const userSobrenome = useSelector((state) => state.auth.user.sobrenome);
  const location = useLocation();
  const [avatar, setAvatar] = useState(AvatarOff);
  const [Finished, setFinished] = useState(false);
  const [MolduraComponent, setMolduraComponent] = useState(Moldura); // Declare MolduraComponent as a state variable

  const isAdmin = useIsAdmin();
  const isEmpresa = useIsEmpresa();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/rhusers/${id}`);
        const userData = response.data;
        const { graduacao } = userData;

        if (userData.departamento === 'Admin' || userData.departamento === 'Empresa') {
          setMolduraComponent(Moldura_Preta);
        } else {
          switch (graduacao) {
            case null:
              setMolduraComponent(Moldura);
              break;
            case 0:
              setMolduraComponent(Moldura);
              break;
            case 1:
              setMolduraComponent(Moldura_Azul);
              break;
            case 2:
              setMolduraComponent(Moldura_Roxa);
              break;
            case 3:
              setMolduraComponent(Moldura_Marrom);
              break;
            default:
              setMolduraComponent(Moldura_Preta);
          }
        }
        setFinished(userData.started);
      } catch (error) {
        console.log('Error:');
      }
    };
    fetchUserData();
  }, [id]);

  useEffect(() => {
    const loadAvatar = async () => {
      try {
        const response = await axios.get('/userimg');
        const userImgs = response.data;

        const userImg = userImgs.find((img) => img.idUser === id);
        if (userImg && userImg.img) {
          setAvatar(userImg.img);
        } else {
          setAvatar(AvatarOff);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadAvatar();
  }, [id]);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(actions.loginFailure());
    history.push('/login');
  };

  // Condições para esconder a navbar
  const hideNavbar = location.pathname === '/' || location.pathname === '/login';

  if (hideNavbar) {
    return null;
  }

  return (
    <>
      {isLoggedIn && (
        <Nav>
          <div className="logo-bar">
            {isAdmin && isLoggedIn && (
              <Link to="/home-admin">
                <Logo className="logo-box" />
              </Link>
            )}
            {isLoggedIn && !isAdmin && !isEmpresa && (
              <Link to="/home">
                <Logo className="logo-box" />
              </Link>
            )}
            {isEmpresa && isLoggedIn && (
              <Link to="/home-empresa">
                <Logo className="logo-box" />
              </Link>
            )}
          </div>

          <div className="div-arrow">
            <MolduraComponent className="moldura" />
            {isLoggedIn &&
              (avatar !== AvatarOff ? (
                <img src={avatar} alt="Avatar" className="logo-avatar" />
              ) : (
                <AvatarOff className="AvatarOff" />
              ))}
            <section className="nome">
              <h3 className="teste">
                {userNome} {userSobrenome}
              </h3>
            </section>
          </div>
        </Nav>
      )}

      {isLoggedIn && (
        <SecondNav>
          <div>
            {isAdmin && isLoggedIn && (
              <Link to="/home-admin" className={location.pathname === '/home-admin' ? 'active' : ''}>
                <FaHome className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Início</StyledP>
              </Link>
            )}

            {isEmpresa && isLoggedIn && (
              <Link to="/home-empresa" className={location.pathname === '/home-empresa' ? 'active' : ''}>
                <FaHome className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Início</StyledP>
              </Link>
            )}
            {isAdmin && isLoggedIn && (
              <button type="button" onClick={handleOpenEditUser} className="button-teste">
                <FaUserCog className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Editar Perfil</StyledP>
              </button>
            )}
            <Modal open={openEditUser} onClose={handleCloseEditUser}>
              {handleCloseEditUser && <EditUser handleClose={handleCloseEditUser} />}
            </Modal>
            {isEmpresa && isLoggedIn && (
              <button type="button" onClick={handleOpenEditUser} className="button-teste">
                <FaUserCog className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Editar Perfil</StyledP>
              </button>
            )}
            <Modal open={openEditUser} onClose={handleCloseEditUser}>
              {handleCloseEditUser && <EditUser handleClose={handleCloseEditUser} />}
            </Modal>
            <Modal open={openRegister} onClose={handleCloseRegister}>
              {handleCloseRegister && <Register handleClose={handleCloseRegister} />}
            </Modal>
            {isEmpresa && isLoggedIn && (
              <Link to="/management" className={location.pathname === '/management' ? 'active' : ''}>
                <FaUserEdit className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Cadastros Gerais</StyledP>
              </Link>
            )}
            {isEmpresa && isLoggedIn && (
              <Link to="/mycourse" className={location.pathname === '/mycourse' ? 'active' : ''}>
                <FaAddressCard className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Permissões</StyledP>
              </Link>
            )}
            {isEmpresa && isLoggedIn && (
              <Link to="/certificationEmp" className={location.pathname === '/certificationEmp' ? 'active' : ''}>
                <FaCertificate className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Certificações</StyledP>
              </Link>
            )}
            {isEmpresa && isLoggedIn && (
              <Link to="/suportEmp" className={location.pathname === '/suportEmp' ? 'active' : ''}>
                <FaQuestionCircle className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Suporte</StyledP>
              </Link>
            )}
            {isAdmin && isLoggedIn && (
              <button type="button" onClick={handleOpenAdmin} className="button-teste">
                <FaUserPlus className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Criar Conta</StyledP>
              </button>
            )}
            <Modal open={openAdmin} onClose={handleCloseAdmin}>
              {handleCloseAdmin && <Admin handleClose={handleCloseAdmin} />}
            </Modal>
            {isAdmin && isLoggedIn && (
              <Link to="/admin-painel" className={location.pathname === '/admin-painel' ? 'active' : ''}>
                <FaUserEdit className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Editar Usuário</StyledP>
              </Link>
            )}
            {isAdmin && isLoggedIn && (
              <Link to="/course-permission" className={location.pathname === '/course-permission' ? 'active' : ''}>
                <FaUserSlash className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP> Permissões </StyledP>
              </Link>
            )}
            {isAdmin && isLoggedIn && (
              <Link to="/course" className={location.pathname === '/course' ? 'active' : ''}>
                <FaAddressCard className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP> Conteúdo </StyledP>
              </Link>
            )}
            {isLoggedIn && !isAdmin && !isEmpresa && (
              <Link to="/home" className={location.pathname === '/home' ? 'active' : ''}>
                <FaHome className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Início</StyledP>
              </Link>
            )}
            {isLoggedIn && !isAdmin && !isEmpresa && (
              <div>
                <button type="button" onClick={handleOpenEditUser} className="button-teste">
                  <FaUserCog className="icon" size={isMobile ? 18 : 24} color="white" />
                  <StyledP>Editar Perfil</StyledP>
                </button>
                <Modal open={openEditUser} onClose={handleCloseEditUser}>
                  {handleCloseEditUser && <EditUser handleClose={handleCloseEditUser} />}
                </Modal>
              </div>
            )}
            {isLoggedIn && Finished && !isAdmin && !isEmpresa && (
              <Link to="/training" className={location.pathname === '/training' ? 'active' : ''}>
                <FaAddressCard className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Capacitações</StyledP>
              </Link>
            )}
            {isLoggedIn && !isAdmin && !isEmpresa && (
              <Link to="/certification" className={location.pathname === '/certification' ? 'active' : ''}>
                <FaCertificate className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Certificações</StyledP>
              </Link>
            )}
            {isLoggedIn && !isAdmin && !isEmpresa && (
              <Link to="/suport" className={location.pathname === '/suport' ? 'active' : ''}>
                <FaQuestionCircle className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>Suporte</StyledP>
              </Link>
            )}
            {isLoggedIn && (
              <Link to="/news" className={location.pathname === '/news' ? 'active' : ''}>
                <FaNewspaper className="icon" size={isMobile ? 18 : 24} color="white" />
                <StyledP>News</StyledP>
              </Link>
            )}
          </div>
          {isLoggedIn && (
            <button type="button" onClick={handleLogout} className="logout-button">
              <FaPowerOff className="icon" size={isMobile ? 18 : 24} color="white" />
              <StyledP> Logout </StyledP>
            </button>
          )}
        </SecondNav>
      )}
    </>
  );
}
