import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Button, InputAdornment, IconButton, TextField } from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordChecklist from 'react-password-checklist';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';
import { ReactComponent as Logo } from '../../assets/img/CyberSensei.svg';
import { Form, Container } from './styled';

function ConfirmAccountPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const dispatch = useDispatch();
  const { token } = useParams();

  const tokenWithoutColon = token.replace(/:/g, '');

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handlePasswordAgainChange = (e) => setPasswordAgain(e.target.value);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handlePasswordValidationChange = (isValid) => setIsPasswordValid(isValid);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== passwordAgain) {
      toast.error('As senhas não coincidem.');
      return;
    }

    if (!isPasswordValid) {
      toast.error('A senha não cumpre todos os requisitos');
      return;
    }

    setIsLoading(true);
    try {
      await dispatch(actions.confirmRequest({ tokenWithoutColon, password }));
      setIsConfirmed(true);
    } catch (error) {
      toast.error('Erro ao confirmar a conta.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isConfirmed) {
    return (
      <Container>
        <Form>
          <Logo className="logo2" />
          <Button className="button" component={Link} to="/login">
            Ir para Login
          </Button>
        </Form>
      </Container>
    );
  }

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <Form onSubmit={handleSubmit}>
        <Logo className="logo" />
        <h1>Confirmação de Conta</h1>
        <TextField
          className="text-field"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end" className="eye">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  className="button-eye"
                  style={{ padding: '10px' }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ className: 'input-label' }}
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          variant="outlined"
        />
        <TextField
          InputLabelProps={{ className: 'input-label' }}
          label="Confirmar Senha"
          className="text-field"
          type={showPassword ? 'text' : 'password'}
          value={passwordAgain}
          variant="outlined"
          onChange={handlePasswordAgainChange}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end" className="eye">
                <IconButton
                  className="button-eye"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  style={{ padding: '10px' }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordChecklist
          className="text-field"
          rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
          minLength={8}
          value={password}
          valueAgain={passwordAgain}
          onChange={handlePasswordValidationChange}
          messages={{
            minLength: 'A senha deve ter no mínimo 8 caracteres',
            specialChar: 'A senha deve conter pelo menos um caractere especial (Ex: !, @, #, etc.).',
            number: 'A senha deve conter pelo menos um número.',
            capital: 'A senha deve conter pelo menos uma letra maiúscula.',
            match: 'As senhas devem coincidir.',
          }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Confirmar Conta
        </Button>
      </Form>
    </Container>
  );
}

export default ConfirmAccountPage;
