import React, { useState, useEffect, useRef } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete'; // Importação do Autocomplete adicionada
import { TextField } from '@material-ui/core';
import axios from '../../services/axios';
import { Form, Title, CheckboxWrapper, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function Edit({ id, history, ...props }) {
  const dispatch = useDispatch();
  const userDepartamento = useSelector((state) => state.auth.user.departamento);
  const UserId = id;
  const cnpj = useSelector((state) => state.auth.user.cnpj_enterprise);
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [cargo, setCargo] = useState('');
  const [setor, setSetor] = useState([]);
  const [funcao, setFuncao] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState(null);
  const [selectedFuncao, setSelectedFuncao] = useState(null);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false);
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    async function getData() {
      try {
        const responseSectors = await axios.get('/sectors');
        const empresaSectors = responseSectors.data.filter((sector) => sector.cnpjCreator === cnpj);
        setSetor(Array.isArray(empresaSectors) ? empresaSectors : []);

        const responseFunctions = await axios.get('/functions');
        const empresaFunctions = responseFunctions.data.filter((func) => func.cnpjCreator === cnpj);
        setFuncao(Array.isArray(empresaFunctions) ? empresaFunctions : []);
      } catch (error) {
        console.error('Failed to fetch sectors and functions', error);
      }
    }

    getData();
  }, [cnpj]);

  const handleOpen = () => {
    setOpen(true);
    document.body.classList.add('modal-open');
  };

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      try {
        const { data } = await axios.get(`/rhusers/${UserId}`);

        if (userDepartamento === 'Empresa' && data.departamento === 'Admin') {
          toast.error('Usuário não existe.');
          history.push('/home-empresa');
          return;
        }

        setNome(data.nome);
        setSobrenome(data.sobrenome);
        setDepartamento(data.departamento);
        setCargo(data.cargo);
        setSelectedSetor(data.setor);
        setSelectedFuncao(data.funcao);
        setEmail(data.email);
        setIsBlocked(data.blocked);

        setIsLoading(false);
        handleOpen();
      } catch (err) {
        setIsLoading(false);
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);
        if (status === 400) errors.map((error) => toast.error(error));
        history.push('/home-empresa');
      }
    }

    getData();
  }, [UserId, history, userDepartamento]);

  const handleSubmit = async (e) => {
    const { handleClose } = props;
    e.preventDefault();

    if (!id) {
      toast.error('Ação não permitida.');
      return;
    }

    let formErrors = false;

    if (password.length > 0 && (password.length < 3 || password.length > 255)) {
      toast.error('A senha precisa ter entre 3 e 255 caracteres');
      formErrors = true;
    }

    if (formErrors) return;

    try {
      setIsLoading(true);

      const userData = {
        nome,
        sobrenome,
        cargo,
        setor: selectedSetor,
        funcao: selectedFuncao,
        departamento,
        email,
        blocked: isBlocked,
      };

      if (password.length > 0) {
        userData.password = password;
      }

      await axios.put(`/rhusers/${id}`, userData);
      toast.success('Colaborador(a) editado(a) com sucesso!');
      dispatch(
        actions.editOtherUserRequest({
          nome,
          userDepartamento,
          sobrenome,
          email,
          cargo,
          setor: selectedSetor,
          funcao: selectedFuncao,
          departamento,
          id,
          history,
        }),
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const status = get(err, 'response.status', 0);
      const data = get(err, 'response.data', {});
      const errors = get(data, 'errors', []);

      if (errors.length > 0) {
        errors.map((error) => toast.error(error));
      } else {
        toast.error('Erro desconhecido');
      }

      if (status === 401) dispatch(actions.loginFailure());
    }
    handleClose();
  };

  return (
    open && (
      <Bloco>
        <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
          <CloseIcon />
        </IconButton>
        <Loading isLoading={isLoading} />
        <Form ref={modalRef} onSubmit={handleSubmit}>
          <div className="box-container">
            <Title> Editar colaborador </Title>
            <h4>Atualize informações de colaboradores</h4>
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              placeholder="Nome"
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Sobrenome"
              value={sobrenome}
              onChange={(e) => setSobrenome(e.target.value)}
              placeholder="Sobrenome"
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Perfil"
              value={departamento}
              onChange={(e) => setDepartamento(e.target.value)}
              placeholder="Departamento"
              disabled
            />
            <Autocomplete
              className="autocomplete"
              disablePortal
              id="combo-box-demo"
              options={setor.map((sector) => sector.sector)} // Opções de setores
              getOptionLabel={(option) => option || ''}
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                setSelectedSetor(newValue);
              }}
              value={selectedSetor}
              renderInput={(params) => (
                <TextField
                  className="text-field"
                  {...params}
                  label="Setor"
                  style={{ boxShadow: 'none' }}
                  inputProps={{ ...params.inputProps, style: { paddingLeft: 10 } }}
                />
              )}
            />
            <Autocomplete
              className="autocomplete"
              disablePortal
              id="combo-box-demo"
              options={funcao.map((func) => func.function)} // Opções de funções
              getOptionLabel={(option) => option || ''}
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                setSelectedFuncao(newValue);
              }}
              value={selectedFuncao}
              renderInput={(params) => (
                <TextField
                  className="text-field"
                  {...params}
                  label="Função"
                  style={{ boxShadow: 'none' }}
                  inputProps={{ ...params.inputProps, style: { paddingLeft: 10 } }}
                />
              )}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true }}
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              disabled
            />
            <CheckboxWrapper>
              <label htmlFor="Bloquear">
                Bloquear
                <input type="checkbox" checked={isBlocked} onChange={() => setIsBlocked(!isBlocked)} />
              </label>
            </CheckboxWrapper>
            <button type="submit">Salvar</button>
          </div>
        </Form>
      </Bloco>
    )
  );
}

Edit.propTypes = {
  match: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
